<template>
  <Page>
    <template v-slot:toolbar-buttons>
      <ButtonLink
        href="/products/add"
        icon=" mdi-plus-circle"
        :text="$t('menu.products.add')"
      />
      <ButtonAction
        :action="exportData"
        icon="mdi-file-export-outline"
        text="Télécharger"
      />
      <ButtonAction
        :action="selectBatchUpdate"
        icon="mdi-file-import-outline"
        text="Mise à jour par lot"
      />
    </template>
    
    <template v-slot:content>
      <ProductFilters
        @onFiltersChanged="onFiltersChanged"
      />
      <v-data-iterator
        :footer-props="table.footerProps"
        :items="result.items"
        :loading="result.loading"
        :options.sync="table.options"
        :server-items-length="result.count"
      >
        <template v-slot:loading>
          <v-sheet
            class="text-center pa-6"
            elevation="3"
            rounded
          >
            <div class="text-center mb-4">
              <v-progress-linear
                indeterminate
                rounded
                height="6"
              />
            </div>
            {{ $t('global.loading') }}
          </v-sheet>
        </template>
        <template v-slot:no-data>
          <v-sheet
            class="text-center pa-6"
            elevation="3"
            rounded
          >
            {{ $t('global.no-data') }}
          </v-sheet>
        </template>
        <template v-slot="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              class="my-4 py-0"
            >
              <v-card>
                <div class="image-wrapper white--text align-end">
                  <v-carousel hide-delimiters>
                    <template v-if="item.photos.length">
                      <v-carousel-item
                        v-for="(image, index) in item.photos"
                        :key="item.id + '-img' + index"
                        :src="image.photo?.src['list-medium'] ?? '../../assets/no-image.webp'"
                        :href="`/products/${item.id}`"
                        target="_blank"
                      />
                    </template>
                    <template v-else>
                      <v-carousel-item
                        :key="item.id + '-img-default'"
                        src="../../assets/no-image.webp"
                        cover
                      />
                    </template>
                  </v-carousel>
                  <v-card-title class="product-title font-weight-bold">
                    <a
                      :href="`/products/${item.id}`"
                      class="text-decoration-none white--text"
                    >
                      {{ item.title }}
                    </a>
                  </v-card-title>
                </div>
                <v-divider />
                <v-card-subtitle>
                  <v-row
                    align="center"
                  >
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      xs="12"
                    >
                      <StateLabel :item="item" />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      xs="12"
                    >
                      <div class="text-right text-h6">
                        <template v-if="item.price?.['@type'] === 'PriceRange'">
                          {{ item.price.min|currency }} - {{ item.price.max|currency }}
                        </template>
                        <template v-else>
                          {{ item.price|currency }}
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
                <v-card-text>
                  <v-row
                    dense
                    no-gutters
                    class="pa-0"
                  >
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      xs="12"
                    >
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td>{{ $t('views.products.forms.labels.reference') }}</td>
                            <td>{{ item.reference }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t('views.products.forms.labels.store') }}</td>
                            <td>{{ item.store.name }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t('views.products.forms.labels.quantity') }}</td>
                            <td v-if="item.stock > 0 ">
                              {{ item.nb_variants }} taille(s) - {{ item.stock }} unité(s)
                            </td>
                            <td v-else>
                              <NoStockChip small />
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t('views.products.forms.labels.category') }}</td>
                            <td>{{ productCategory(item.category) }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t('views.products.forms.labels.other') }}</td>
                            <td>
                              <v-icon
                                v-if="item.in_imparfaite_warehouse"
                                color="teal darken-4"
                                class="ml-2 mr-2"
                              >
                                mdi-warehouse
                              </v-icon>
                              <v-icon
                                v-else
                                color="blue-grey lighten-4"
                                class="ml-2 mr-2"
                              >
                                mdi-warehouse
                              </v-icon>
                            
                              <v-icon
                                v-if="item.favorite"
                                title="Coup de coeur"
                                class="ml-2 mr-2"
                                color="teal darken-4"
                              >
                                mdi-heart
                              </v-icon>
                              <v-icon
                                v-else
                                class="ml-2 mr-2"
                                color="blue-grey lighten-4"
                              >
                                mdi-heart
                              </v-icon>
                            
                              <v-icon
                                v-if="item.sale_point"
                                title="Est associé à un point de vente"
                                color="teal darken-4"
                                class="mr-2"
                              >
                                mdi-store-alert
                              </v-icon>
                              <v-icon
                                v-else
                                class="ml-2 mr-2"
                                color="blue-grey lighten-4"
                              >
                                mdi-store-alert
                              </v-icon>
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t('views.products.forms.labels.commission_models.' + item.commission.model) }}</td>
                            <td>
                              {{ item.commission.vat_mode }} /
                              {{ item.commission.rate | rate }} + {{ item.commission.fee.amount | currency }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t('views.products.forms.labels.drops') }}</td>
                            <td>
                              <template v-for="(dropId) in item.drop_ids">
                                <v-chip
                                  :key="'drop' + dropId"
                                  color="secondary"
                                  class="ma-2"
                                  small
                                >
                                  {{ dropLabel(dropId) }}
                                </v-chip>
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <LinkProduct
                    v-if="item.market_place_permalink"
                    :link="item.market_place_permalink"
                    small
                  />
                  <v-spacer />
                  <v-menu
                    top
                    left
                    offset-y
                    min-width="300"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        :href="`/products/${item.id}`"
                        target="_blank"
                        class="py-0"
                      >
                        <v-list-item-icon
                          class="mr-2"
                        >
                          <v-icon
                            small
                            color="blue lighten-1"
                          >
                            mdi-note-edit
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>
                          {{ $t('forms.buttons.edit') }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        :href="'/products/add/?fromId='+item.id"
                        target="_blank"
                        class="py-0"
                      >
                        <v-list-item-icon
                          class="mr-2"
                        >
                          <v-icon
                            small
                            color="blue lighten-1"
                          >
                            mdi-content-duplicate
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>
                          {{ $t('forms.buttons.duplicate') }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        class="py-0"
                        @click="deleteProduct(item)"
                      >
                        <v-list-item-icon
                          class="mr-2"
                        >
                          <v-icon
                            small
                            color="red lighten-2"
                          >
                            mdi-delete
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>
                          {{ $t('forms.buttons.delete') }}
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <Snackbar />
      <BatchUpdateModal
        :opened.sync="modal.import.opened"
        @productsUpdated="onProductsUpdated"
      />
    </template>
  </Page>
</template>

<script>
import { getQueryAsArray, getQueryString } from '@/helpers/queryString'
import BatchUpdateModal from '@/views/Products/Modal/BatchUpdate.vue'
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import ButtonLink from '@/components/base/Toolbar/ButtonLink.vue'
import EventBus from '@/plugins/event-bus'
import LinkProduct from '@/components/base/LinkExternal/Product.vue'
import { mapState } from 'vuex'
import NoStockChip from '@/components/base/Product/NoStockChip.vue'
import Page from '@/components/core/Page.vue'
import ProductFilters from '@/views/Products/Filters.vue'
import Snackbar from '@/components/core/Snackbar'
import snackbarMixin from '@/mixins/snackbar'
import StateLabel from '@/components/core/StateLabel'

export default {
  name: 'ProductsList',
  components: {
    LinkProduct,
    NoStockChip,
    ButtonLink,
    Page,
    ButtonAction,
    BatchUpdateModal,
    ProductFilters,
    Snackbar,
    StateLabel,
  },
  mixins: [ snackbarMixin ],
  data() {
    return {
      editedIndex: -1,
      editedItem: null,
      request: null,
      loading: false,
      table: {
        options: {
          page: +this.$route.query.page || 1,
          itemsPerPage: 30,
          sortBy: [],
          sortDesc: [],
        },
        footerProps: {
          'items-per-page-options': [30],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      filters: {
        full_reference: this.$route.query.full_reference,
        query: this.$route.query.query,
        'state_and_reason[]': getQueryAsArray(this.$route.query, 'state_and_reason[]'),
        'store.id': this.$route.query['store.id'],
        'drop.id': this.$route.query['drop.id'],
        in_imparfaite_warehouse: this.$route.query['in_imparfaite_warehouse'],
        'category_id[]': getQueryAsArray(this.$route.query, 'category_id[]'),
      },
      modal: {
        import: {
          opened: false,
        },
      },
    }
  },
  computed: {
    ...mapState(['drops']),
    queryString: function () {
      return 'v3/products?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'ProductsList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onProductsUpdated(message) {
      if (message !== undefined && message.text !== null) {
        EventBus.$emit('SNACKBAR', { color: message.type, message: message.text, link: message.link })
      }
    },
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'ProductsList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.result.loading = true
      this.result.items = []
      this.result.count = 0
      
      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
          this.result.loading = false
        })
    },
    productCategory(category) {
      return category?.short_names.fr ?? '-'
    },
    dropLabel(dropId) {
      const drop = this.drops.find(drop => drop.id === dropId) ?? null
      
      return drop === null ? 'unknown' : drop.name + ' (' + drop.slug + ')'
    },
    deleteProduct(item) {
      this.result.loading = true
      this.editedIndex = this.result.items.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.$axios.delete('v3/products/' + item.id)
        .then((response) => {
          this.editedItem = response.data

          if (this.editedIndex > -1) {
            Object.assign(this.result.items[this.editedIndex], this.editedItem)
          }
          this.snackbarSuccess(
            this.$i18n.t('views.products.forms.messages.delete.succeed', { productTitle: item.title })
          )
        })
        .catch((error) => {
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbarError(this.$i18n.t('views.products.forms.messages.delete.failed', { error: error_message }))
        }) .finally(() => {
          this.result.loading = false
          this.editedIndex = -1
        })
    },
    exportData() {
      this.loading = true
      
      this.$axios.post('v3/products/export', {
        filters: {
          state_and_reason: this.filters['state_and_reason[]'] ?? null,
          'store.id': this.filters['store.id'] ?? null,
          'drop.id': this.filters['drop.id'] ?? null,
          category_id: this.filters['category_id[]'] ?? null,
          in_imparfaite_warehouse: this.filters['in_imparfaite_warehouse'] ?? null,
          full_reference: this.filters['full_reference'] ?? null,
        },
      }).then(response => {
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8,' })
        const url = window.URL.createObjectURL(blob)
        
        window.open(url)
      }).catch((error) => {
        if (!this.$axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbarError(this.$i18n.t('views.products.forms.messages.export.failed', { error: error_message }))
          this.loading = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
    selectBatchUpdate() {
      this.modal.import.opened = true
    },
  },
}
</script>

<style scoped>
.image-wrapper {
    position: relative;
}

.product-title {
    background-color: rgba(0,0,0,0.4);
    bottom: 0;
    color: #fff;
    position: absolute;
    width: 100%;
}
</style>
